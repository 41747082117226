import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import JobFilterController from "./job_filter_controller"
application.register("job-filter", JobFilterController)

import MobileViewController from "./mobile_view_controller"
application.register("mobile-view", MobileViewController)

import ModalController from "./modal_controller"
application.register("modal", ModalController)

export { application }
