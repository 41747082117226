import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container']

  static values = {
    openOnLoad: Boolean,
  }

  static classes = [ "visibility" ]

  connect() {
    let closeEmbedFormModal = sessionStorage.getItem("close-embed-form-modal")
    if (this.openOnLoadValue && closeEmbedFormModal === null) {
      sessionStorage.setItem("close-embed-form-modal", true)
      this.open();
    }
  }

  disconnect() {
    this.close();
  }

  open() {
    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    this.containerTarget.classList.add(this.visibilityClass);
  }

  close() {
    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    this.containerTarget.classList.remove(this.visibilityClass);
    this.element.remove();
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Add classes to body to fix its position
    document.body.classList.add('lock-body-scroll');

    // Save the scroll position
    this.saveScrollPosition();

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('lock-body-scroll');

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.scrollY || document.body.scrollTop;
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return;

    document.documentElement.scrollTop = this.scrollPosition;
  }
}
