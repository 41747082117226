import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "sidebar", "contentList", "mainContainer" ]

  showContent() {
    this.sidebarTarget.classList.add("s-hide")
    this.contentListTarget.classList.add("s-hide")
    this.mainContainerTarget.classList.add("display-block")
  }

  hideContent() {
    this.sidebarTarget.classList.remove("s-hide")
    this.contentListTarget.classList.remove("s-hide")
    this.mainContainerTarget.classList.remove("display-block")
  }

  back() {
    this.hideContent()
    history.back()
  }
}
